<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ formTitle }}</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field v-model="item.id" label="id"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-file-input show-size label="Select Image" accept="image/*" v-model="image" @change="Preview_image"></v-file-input>
            <v-img :src="url"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
      <v-btn color="blue darken-1" text @click="upload"> Upload </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    item: {
      id: '',
    },
  },

  data() {
    return {
      dialog: true,
      formTitle: 'Edit Product',
      image: [],
      url: '',
    }
  },

  computed: {
    requestBody() {
      return {
        product_id: this.item.id,
        image: this.image,
      }
    },
  },

  methods: {
    close() {
      this.$emit('close')
    },

    Preview_image() {
      this.url = URL.createObjectURL(this.image)
    },

    upload() {
      this.$store
        .dispatch('uploadImage', this.requestBody)
        .then(() => {
          this.close()
        })
        .catch((error) => {
          console.log(error)
          this.close()
        })
    },
  },
}
</script>

<style></style>
