<template>
  <v-footer>
    <span>&copy; 2020 </span>
  </v-footer>
</template>

<script>
export default {}
</script>

<style></style>
