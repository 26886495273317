<template>
  <v-app-bar app color="primary" class="flex-grow-0" dark>
    <router-link to="/" class="mx-2">
      <v-btn icon color="rgba(255,255,255,0.5)">
        <v-icon small color="#fff">fas fa-home</v-icon>
      </v-btn>
    </router-link>

    <h4>دکورآرای</h4>

    <v-spacer></v-spacer>

    <!-- if user is not loged in , show login button -->
    <router-link  v-if="!$store.state.token" to="/login">
      <v-btn outlined color="rgba(255,255,255,0.5)">
        <v-icon small color="#fff">fas fa-user</v-icon>
      </v-btn>
    </router-link>
    

  </v-app-bar>
</template>

<script>
export default {}
</script>

<style></style>
