<template>
  <v-main>
    <v-container>
      <div class="home">
        <img alt="Vue logo" src="../assets/logo.png" />
      </div>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'DashboardView',
  components: {},
}
</script>
