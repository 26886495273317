<template>
  <v-card class="pb-8" :loading="loading">
    <h3 class="text-center mb-4 pt-8">{{ title }}</h3>

    <v-form ref="form" @submit.prevent class="pa-4">
      <v-card>
        <v-tabs v-model="step" grow background-color="primary" dark>
          <v-tab>مشخصات</v-tab>
          <v-tab :disabled="!itemId && step !== 1">تصاویر</v-tab>
          <v-tab :disabled="!itemId && step !== 2">تنوع</v-tab>

          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-text-field v-model="item.title" @input="updateSlug" label="عنوان" :rules="required_rule" required />
                <v-text-field v-model="item.slug" label="اسلاگ" :rules="required_rule" required />
                <v-autocomplete
                  v-model="item.categories"
                  :items="categoriesArrayList"
                  :rules="required_rule"
                  required
                  label="دسته بندی"
                  item-text="name"
                  deletable-chips
                  item-value="id"
                  multiple
                  chips
                />
                <v-textarea v-model="item.description" label="توضیحات" rows="3" :rules="required_rule" required />
                <v-textarea v-model="item.dimensions" label="ابعاد" rows="3" :rules="required_rule" required />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-row>
                  <template v-if="itemId && item.images && item.images?.length">
                    <v-col cols="12">
                      <h3 class="mt-4">تصاویر موجود</h3>
                    </v-col>
                    <v-col class="d-flex child-flex position-relative" cols="3" v-for="(imageUrl, index) in item.images" :key="`img-col-${index}`">
                      <v-hover v-slot="{ hover }">
                        <div>
                          <v-img aspect-ratio="1" :src="imageUrl" :key="`image-${index}`" />
                          <v-fade-transition>
                            <v-overlay v-if="hover" absolute color="#036358" class="pa-4">
                              <v-btn dark fab small @click="handleDeleteImage(imageUrl)">
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </v-overlay>
                          </v-fade-transition>
                        </div>
                      </v-hover>
                    </v-col>
                  </template>
                </v-row>
                <h3 class="mt-8">بارگذاری تصاویر جدید</h3>
                <v-file-input v-model="images" label="بارگذاری تصویر" multiple prepend-icon="mdi-camera" />
                <div class="d-flex">
                  <v-row>
                    <v-col class="d-flex child-flex position-relative" cols="3" v-for="(imageUrl, index) in imageUrls" :key="`img-col-${index}`">
                      <v-hover v-slot="{ hover }">
                        <div>
                          <v-img aspect-ratio="1" :src="imageUrl" :key="`image-${index}`" />
                          <v-fade-transition>
                            <v-overlay v-if="hover" absolute color="#036358" class="pa-4">
                              <v-btn dark fab small @click="handleDeleteFile(index)">
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </v-overlay>
                          </v-fade-transition>
                        </div>
                      </v-hover>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <div class="d-flex justify-end mb-6">
                  <v-btn color="primary" @click="addVariation"><v-icon class="ml-2">mdi-plus</v-icon>افزودن تنوع</v-btn>
                </div>
                <v-container>
                  <v-row>
                    <v-col cols="1">#</v-col>
                    <v-col cols="3">قیمت</v-col>
                    <v-col cols="3">رنگ</v-col>
                    <v-col cols="2">تعداد</v-col>
                    <v-col cols="2">وضعیت</v-col>
                    <v-col cols="1">حذف</v-col>
                  </v-row>
                  <v-divider class="my-4" />
                  <template v-if="item.variations.length">
                    <template v-for="(variation, index) in item.variations">
                      <v-row :key="`variation-row-${index}`">
                        <v-col class="py-0 d-flex align-center justify-center" cols="1">{{ index + 1 }}</v-col>
                        <v-col class="py-0" cols="3">
                          <v-text-field v-model="variation.price" label="قیمت" :rules="required_rule" required />
                        </v-col>
                        <v-col class="py-0" cols="3">
                          <v-text-field v-model="variation.color" label="رنگ" :rules="required_rule" required />
                        </v-col>
                        <v-col class="py-0" cols="2">
                          <v-text-field v-model="variation.stock" label="تعداد" />
                        </v-col>
                        <v-col class="py-0 d-flex align-center justify-center" cols="2">
                          <v-switch v-model="variation.active" />
                        </v-col>
                        <v-col class="py-0 d-flex align-center justify-center" cols="1">
                          <v-btn color="error" icon @click="removeVariation(index, variation.id)"><v-icon>mdi-delete</v-icon></v-btn>
                        </v-col>
                      </v-row>
                      <v-divider v-if="index !== item.variations.length - 1" class="my-4" :key="index" />
                    </template>
                  </template>
                  <template v-else>
                    <div class="text-center">هیچ تنوعی وجود ندارد</div>
                  </template>
                </v-container>
              </v-card-text>
            </v-card>
          </v-tab-item>

        </v-tabs>
      </v-card>

      <v-divider class="my-6" />
      <div class="d-flex justify-end">
        <v-btn color="secondary" text @click="close" class="mx-2"> انصراف </v-btn>
        <v-btn color="primary" type="submit" @click="submitForm" :loading="loading">{{ buttonText }} </v-btn>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['itemId'],

  data() {
    return {
      item: {
        title: '',
        slug: '',
        description: '',
        dimensions: '',
        categories: [],
        variations: [],
      },
      dialog: true,
      step: 0,
      images: [],
      loading: false,
      createdItemId: '',
    }
  },

  computed: {
    ...mapGetters({
      categoriesList: 'getCategories',
    }),


    // recrusive function to get all categories and its children in one array
    categoriesArrayList() {
      let list = []
      let getChildren = (category) => {
        list.push(category)
        if (category.children) {
          category.children.forEach((child) => {
            getChildren(child)
          })
        }
      }
      this.categoriesList.forEach((category) => {
        getChildren(category)
      })
      return list
    },


    title() {
      return this.itemId ? 'ویرایش محصول' : 'افزودن محصول جدید'
    },

    required_rule() {
      return [(v) => 
        !!(v !== undefined && v !== null 
        && (
          (typeof v === 'string' && v.length) ||
          (typeof v === 'number' && v.toString().length) ||
          (Array.isArray(v) && v.length)
        )
        ) || 'نمی‌تواند خالی باشد'];
      //
    },

    imageUrls() {
      return this.images.map((image) => URL.createObjectURL(image))
    },

    buttonText() {
      return this.itemId
        ? this.step === 0
          ? 'ویرایش'
          : this.step === 1
          ? 'بارگذاری تصاویر'
          : 'ذخیره تنوع ها'
        : [0, 1].includes(this.step)
        ? 'ادامه'
        : 'ذخیره'
    },
  },

  methods: {
    submitForm() {
      if (this.$refs.form.validate()) {
        this.itemId && this.step === 0
          ? this.update()
          : this.ItemId && this.step === 2
          ? this.updateVariations()
          : this.step === 0
          ? this.createProduct()
          : this.step === 1
          ? this.uploadImages()
          : this.postVariations()
      }
    },

    close() {
      this.$emit('close')
    },

    updateSlug() {
      this.item.slug = this.item.title?.replace(/ /g, '-')?.toLowerCase()
    },

    createProduct() {
      this.loading = true
      this.$store
        .dispatch('saveProduct', this.item)
        .then((res) => {
          this.createdItemId = res.data.id
          this.step = 1
        })
        .finally(() => (this.loading = false))
    },

    uploadImages() {
      let payload = {
        product_id: this.createdItemId,
        images: this.images,
      }
      this.loading = true
      this.$store
        .dispatch('uploadImage', payload)
        .then(() => {
          if (this.itemId) {
            this.close()
          } else {
            this.step = 2
          }
        })
        .finally(() => (this.loading = false))
    },

    postVariations() {
      let payload = {
        id: this.createdItemId,
        variations: this.item.variations,
      }
      this.loading = true
      this.$store
        .dispatch('saveVariations', payload)
        .then(() => {
          this.clearForm()
          this.close()
        })
        .finally(() => (this.loading = false))
    },

    update() {
      this.loading = true
      this.$store
        .dispatch('updateProduct', this.item)
        .then(() => this.close())
        .finally(() => (this.loading = false))
    },

    updateVariations() {
      let payload = {
        id: this.itemId,
        variations: this.item.variations,
      }
      this.loading = true
      this.$store
        .dispatch('updateVariations', payload)
        .then(() => {
          this.clearForm()
          this.close()
        })
        .finally(() => (this.loading = false))
    },

    handleDeleteFile(index) {
      this.images.splice(index, 1)
    },

    addVariation() {
      this.item.variations.push({
        price: '',
        color: '',
        size: '',
        stock: '',
        active: true,
      })
    },

    removeVariation(index, id) {
      this.item.variations.splice(index, 1)
      if (id) {
        this.loading = true
        this.$store.dispatch('deleteVariation', id).finally(() => (this.loading = false))
      }
    },

    clearForm() {
      this.step = 0
      this.item = {
        title: '',
        slug: '',
        description: '',
        categories: [],
        variations: [],
      }
      this.images = []
      this.createdItemId = ''
      this.$refs.form?.reset()
    },

    handleDeleteImage(url) {
      this.loading = true
      this.$store
        .dispatch('deleteImage', url)
        .then(() => {
          this.item.images = this.item.images.filter((image) => image !== url)
        })
        .finally(() => (this.loading = false))
    },
  },

  watch: {
    itemId: {
      handler() {
        this.clearForm()
        if (this.itemId) {
          this.loading = true
          this.$store
            .dispatch('showProduct', this.itemId)
            .then((res) => {
              this.item = res.data
              this.createdItemId = res.data.id
            })
            .finally(() => (this.loading = false))
        }
      },
      immediate: true,
    },
  },

  destroyed() {
    this.clearForm()
  },
}
</script>
