import Vue from "vue";
import VueRouter from "vue-router";
import DashboardView from "../views/DashboardView.vue";
import ProductsView from "../views/ProductsView.vue";
import { auth } from "@/middleware/auth";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: DashboardView,
    meta: {
      middleware: auth,
    },
  },
  {
    path: "/products",
    name: "products",
    component: ProductsView,
    meta: {
      middleware: auth,
    }
  },
  {
    path: "/product/:id",
    name: "product",
    component: () =>
      import(/* webpackChunkName: "product" */ "../views/ProductView.vue"),
    meta: {
      middleware: auth,
    },
  },
  {
    path: "/orders",
    name: "orders",
    component: () =>
      import(/* webpackChunkName: "orders" */ "../views/OrdersView.vue"),
    meta: {
      middleware: auth,
    },
  },
  {
    path: "/colors",
    name: "colors",
    component: () =>
      import(/* webpackChunkName: "colors" */ "../views/ColorsView.vue"),
    meta: {
      middleware: auth,
    },
  },
  // orderDetail
  {
    path: "/order/:id",
    name: "order",
    component: () =>
      import(/* webpackChunkName: "orders" */ "../views/OrderView.vue"),
    meta: {
      middleware: auth,
    },
  },
  {
    path: "/categories",
    name: "cartegories",
    component: () =>
      import(/* webpackChunkName: "orders" */ "../views/CategoriesView.vue"),
    meta: {
      middleware: auth,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
