import Axios, { AxiosInstance } from 'axios'

const axios = Axios.create({
  baseURL: 'https://api.decoraray.ir/api/v1',
  // baseURL: 'http://localhost:8080/api/v1/',
  timeout: 600000, // 10 minutes 
  headers: {
    'accept-language': 'en-US',
    accept: 'application/json',
    'content-type': 'application/json',
  },
})

const token = localStorage.getItem('token');
axios.defaults.headers.common["Authorization"] = token ? "Bearer " + token : "";

axios.interceptors.response.use(
  (response) => {
    if (response.data.token) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token
      localStorage.setItem('token', response.data.token)
    }
    return response
  },
  (error) => {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      localStorage.removeItem('token')
      return router.push('/login')
    }
    return Promise.reject(error)
  }
)

export default axios
