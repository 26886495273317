<template>
	<ul :class="!mobile && 'menus-list'" class="flex font-iranyekan list-none pt-7 space-x-6 space-x-reverse" >
		<li @click="handleMenuClick('')">
			<span>همه</span>
		</li>
		<li :key="cat.slug" v-for="cat in menu" >
				<span @click="handleMenuClick(cat.slug)">{{ cat.name }}</span>
			<div :id="!mobile && 'i7scsd'" class="menu-wrapper" style='z-index:20001;'  v-if="cat.children.length">
				<ul class="sub-menu">
					<li v-for="subcat in cat.children" @click="handleMenuClick(subcat.slug)">
						<span>
							{{ subcat.name }}
						</span>
					</li>
				</ul>
			</div>
		</li>
	</ul>
</template>

<script>
	export default {
		name: "NavMenu",
		props: {
			menu: Array,
			mobile: {
				type: Boolean,
				default: false,
			},
		},
		methods: {
			handleMenuClick(slug) {
				this.$emit("selected",slug);
			},
		},
	};
</script>

<style lang="scss" scoped></style>
