<template>
  <v-navigation-drawer permanent>
    <v-list dense nav>
      <v-list-item v-for="item in items" :key="item.title" :to="item.to">
        <div class="d-flex">
          <v-icon color="primary" dense right>{{ item.icon }}</v-icon>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </div>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: 'داشبورد', icon: 'fas fa-shopping-cart', to: '/' },
        { title: 'کاربر', icon: 'fas fa-user', to: '/users' },
        { title: 'محصولات', icon: 'fas fa-gift', to: '/products' },
        { title: 'سفارشات', icon: 'fas fa-truck-fast', to: '/orders' },
        {
          title: 'دسته بندی ها',
          icon: 'fas fa-book',
          to: '/categories',
        },
        { title: 'تنظیمات', icon: 'fas fa-gears', to: '/settings' },
      ],
      right: null,
    }
  },
}
</script>

<style></style>
