<template>
	<v-text-field
		:loading="loading"
		density="compact"
		variant="solo"
		clear-icon="mdi-close-circle"
		clearable
		label="جستجوی مجصولات"
		prepend-inner-icon="mdi-magnify"
		single-line
		hide-details
		v-model="searchKeyword"
		@click:prepend-inner="onClick"
	></v-text-field>
</template>

<script>
  export default {
    data: () => ({
      loaded: false,
      loading: false,
	  searchKeyword: null,
    }),

    methods: {
      onClick () {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          this.loaded = true
        }, 1000)
		this.$emit('search' , this.searchKeyword)
      },
    },
  }
</script>

<style lang="scss" scoped></style>
