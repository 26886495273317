<template>
  <div id="app">
    <v-app>
      <app-bar />

      <NavigationDrawer class="admin__navigation-drawer" />

      <v-main class="admin__main">
        <v-container fluid>
          <router-view />
        </v-container>
      </v-main>

      <Footer />
    </v-app>
  </div>
</template>

<script>
import NavigationDrawer from './components/NavigationDrawer.vue'
import AppBar from './components/AppBar.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',

  components: {
    NavigationDrawer,
    AppBar,
    Footer,
  },
}
</script>

<style lang="scss">
	@import "~/assets/scss/main.scss";
	@import "https://ibdok.ir/cdn/fontiran-VNXMNSAT.css";
	@import "~/assets/scss/vuetify";

</style>